const queryStringFiltersMixin = {
  methods: {
    // These are meant to be used with oto-date-range-picker
    handleApplyDate(event) {
      // todo: remove logs
      console.log(event)
      if (event.start.split('T')[0]) {
        this.queryString.start_date = event.start.split('T')[0]
      } else {
        this.queryString.start_date = event.start
      }

      if (event.end.split('T')[0]) {
        this.queryString.end_date = event.end.split('T')[0]
      } else {
        this.queryString.end_date = event.end
      }

      // this.queryString.end_date = event.end;
    },
    handleCancelDate(event) {
      // todo: remove logs
      console.log(event)
      this.queryString.start_date = ''
      this.queryString.end_date = ''
    },
    // This is meant to be used with fs-table-* components
    handleSorting(event) {
      // todo: remove logs
      console.log(event)
      this.queryString.sort = event.sort
      this.queryString.order = event.order
    },
  },
}

module.exports = queryStringFiltersMixin
