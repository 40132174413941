<template>
  <base-layout>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div class="">
            <title-plus title="IOT Management" :hidePlus="true" />
          </div>
          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="handleApplyDate($event)"
              @cancel-date="handleCancelDate($event)"
            />
          </div>
        </div>

        <div
          class="flex flex-col mt-4 mb-10 space-y-4 md:space-y-0 md:items-center md:flex-row"
        >
          <card-tax-stat
            title="Number of Locks"
            :value="indexMetaData.summary.total"
            variant="yellow"
            class="mr-3"
          />
          <card-tax-stat
            title="Assigned Locks"
            :value="indexMetaData.summary.assigned"
            variant="light-blue"
            class="mr-3"
          />
          <card-tax-stat
            title="Unassigned Locks"
            :value="indexMetaData.summary.unassigned"
            variant="orange"
            class="mr-3"
          />
          <card-tax-stat
            title="Damaged Locks"
            :value="indexMetaData.summary.damaged"
            variant="red"
            class=""
          />
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end ">
            <export-as-dropdown />
          </div>
        </div>
      </div>

      <fsTableContainer class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem> #{{ item.id | onlyLastFive }} </fsTableRowItem>

            <fsTableRowItem>
              <div v-if="item.vehicle_id">
                <router-link
                  :to="{
                    name: 'ViewVehicleProfile',
                    params: { id: item.vehicle_id },
                  }"
                >
                  <span class="text-blue-500">{{ item.name }}</span>
                </router-link>
              </div>
              <div v-else>
                {{ item.name }}
              </div>
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.vehicle_id || '--' }}
            </fsTableRowItem>

            <fsTableRowItem>
              <battery-status :level="item.power_level" />
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.lock_id }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.sim_number }}
            </fsTableRowItem>

            <fsTableRowItem>
              <div class="flex items-center justify-center">
                <i
                  :class="[
                    item.is_operational
                      ? 'fas fa-exclamation-circle text-red-500'
                      : 'fas fa-check-circle text-green-500',
                  ]"
                />
              </div>
            </fsTableRowItem>

            <fsTableRowItem>
              <x-status
                :variant="item.is_locked ? 'green' : 'orange'"
                :text="item.is_locked ? 'Locked' : 'Unlocked'"
                profile="trip"
              />
            </fsTableRowItem>

            <fsTableRowItem>
              <div class="flex items-center">
                <oto-edit-icon @click="edit(item)" />
                <MoreActionsDropdown
                  :currentVal="item.is_operational"
                  :onOffEndpoint="getActionsEndpoint(item.id)"
                  actionOnKey="is_operational"
                  actionOffKey="is_operational"
                  :actionOnVal="true"
                  :actionOffVal="false"
                  @actionOn="item.is_operational = true"
                  @actionOff="item.is_operational = false"
                />
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>
        <!-- fs-table-footer -->
        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
        <!-- fs-table-footer -->
      </fsTableContainer>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoSearch from '@/components/ui/OtoSearch'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import XStatus from '@/components/badge/XStatus'
import BatteryStatus from '@/components/badge/BatteryStatus'

import CardTaxStat from '@/components/cards/TaxStat'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'

import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'

import { LockConfig } from '@/config/LockConfig'

//made by guang
import { MultipleScooterConfig } from '@/config/MultipleScooterConfig'
import { EventBus } from '@/utils/EventBus'
import MoreActionsDropdown from '@/components/dropdown/MoreActionsDropdown'

export default {
  name: 'Fleets',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
  ],
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    ExportAsDropdown,
    OtoSearch,
    OtoEditIcon,
    DateRangePicker,
    CardTaxStat,
    XStatus,
    BatteryStatus,
    MoreActionsDropdown,
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total: 0,
          assigned: 0,
          unassigned: 0,
          damaged: 0,
        },
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: LockConfig.api.index,
      tableHeaders: [
        {
          name: 'ID',
          width: '10%',
          field: 'id',
          sort: null,
          order: '',
        },
        {
          name: 'IOT Name',
          width: '15%',
          field: 'name',
          sort: true,
          order: '',
        },
        {
          name: 'Assigned Vehicle',
          width: '15%',
          field: 'vehicle_id',
          sort: true,
          order: '',
        },
        {
          name: 'Battery Charge',
          width: '10%',
          field: 'power_level',
          sort: true,
          order: '',
        },
        {
          name: 'IMEI Code',
          width: '10%',
          field: 'qr_code',
          sort: true,
          order: '',
        },
        {
          name: 'SIM Number',
          width: '1%',
          field: 'qr_code',
          sort: true,
          order: '',
        },
        {
          name: 'Operational',
          width: '10%',
          field: 'is_operational',
          sort: true,
          order: '',
        },
        // {
        //   name: "MSISDN",
        //   width: "10%",
        //   field: "sim_number",
        //   sort: true,
        //   order: ""
        // },
        // {
        //   name: "Firmware Key",
        //   width: "10%",
        //   field: "firmware_key",
        //   sort: null,
        //   order: ""
        // },
        {
          name: 'Lock Status',
          width: '10%',
          field: 'is_locked',
          sort: true,
          order: '',
        },
        {
          name: 'Actions',
          width: '10%',
          field: null,
          sort: null,
          order: '',
        },
      ],
    }
  },
  async mounted() {
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    window.addEventListener(LockConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    add: function() {
      // console.log('Multiple = ' , MultipleScooterConfig.events.sorToggle)
      EventBus.$emit(MultipleScooterConfig.events.editingData, {})
      dispatchEvent(new Event(MultipleScooterConfig.events.sorToggle))
      this.is_edit = false
      // EventBus.$emit(FleetConfig.events.editingData, {
      //   // enable status-switch by default
      //   status_active: true
      // });
      // dispatchEvent(new Event(FleetConfig.events.sorToggle));
      // console.log("asdfdsaf");
    },
    edit: function(item) {
      console.log(item)
      EventBus.$emit(MultipleScooterConfig.events.editingData, item)
      dispatchEvent(new Event(MultipleScooterConfig.events.sorToggle))
      this.is_edit = true
      // EventBus.$emit(SubscriptionConfig.events.editingData, item);
      // dispatchEvent(new Event(SubscriptionConfig.events.sorToggle));
    },
    view: function(item) {
      console.log(item)
      // EventBus.$emit(SubscriptionConfig.events.viewingData, item);
      // this.$modal.show(SubscriptionConfig.events.viewingData);
    },
    getActionsEndpoint(id) {
      return LockConfig.api.update(id)
    },
  },
}
</script>

<style></style>
