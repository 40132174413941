<template>
  <div>
    <t-dropdown>
      <template
        #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          class="flex items-center justify-between py-0 pl-4 font-medium border rounded-full shadow-sm text-14px text-oCharcoal focus:border-oCharcoal hover:border-oCharcoal focus:outline-none"
          style="height: 35px; width: 155px"
          aria-label="Export As Dropdown Menu"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <div class="flex items-center">
            <svg
              class="w-6 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            <span class="text-gray-700">
              Export As
            </span>
          </div>
          <div
            class="flex items-center justify-center m-0 bg-gray-100 rounded-r-full"
            style="height:33px; width:35px"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </button>
      </template>

      <template #default="{ hide }">
        <div class="py-1 mt-px bg-white rounded-md shadow-md">
          <t-dropdown-item
            @click="
              $emit('csv')
              hide()
            "
          >
            CSV
          </t-dropdown-item>

          <t-dropdown-item
            @click="
              $emit('excel')
              hide()
            "
          >
            EXCEL
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
  </div>
</template>

<script>
import TDropdownItem from '@/components/dropdown/TDropdownItem'

export default {
  name: 'ExportAsDropdown',
  components: {
    TDropdownItem,
  },
  props: {},
}
</script>

<style></style>
