<template>
  <th :width="item.width" :class="headItemClass">
    <div
      v-if="item.sort === null"
      :class="className ? className : type == 'permission' && 'text-center'"
    >
      {{ item.name }}
    </div>
    <div v-else>
      <button :class="headItemClass" @click="applySorting(item)">
        {{ item.name }} {{ indicator }}
      </button>
    </div>
  </th>
</template>

<script>
export default {
  name: 'iTableHeadItem',
  props: {
    item: {
      type: Object,
    },
    type: {
      required: false,
    },
    className: {
      required: false,
      type: String,
      default: null,
    },
  },
  data: function() {
    return {
      indicator: ' ',
      headItemClass:
        'py-2 px-custom font-semibold tracking-wider text-left text-gray-600 text-14px text-oCharcoal outline-none focus:outline-none',
    }
  },
  mounted: function() {
    // todo: detach listener
    // todo: event data isn't needed, can be removed
    window.addEventListener('reset-other-sortings', () => {
      // console.log("listened reset");
      // console.log(event.detail.sort === this.item.field);
      // setting item.order to empty-string is enough, watchers will handle the rest
      this.item.order = ''
    })
  },
  watch: {
    item: {
      handler: function(updatedItem) {
        console.log(updatedItem)
        if (updatedItem.order === 'asc') {
          this.indicator = '▲'
          return
        }
        if (updatedItem.order === 'desc') {
          this.indicator = '▼'
          return
        }
        this.indicator = ' '
        return
      },
      deep: true,
    },
  },
  methods: {
    applySorting: function() {
      if (this.item.sort === null) {
        // sorting is disabled
        return
      }
      const data = {
        sort: this.item.field,
        order: this.getOrder(this.item.order),
      }
      // emit apply-sorting event
      this.$emit('apply-sorting', data)
      // emit reset-other-sortings event
      dispatchEvent(new CustomEvent('reset-other-sortings', { detail: data }))

      // Change the order state
      this.item.order = data.order

      // todo: remove logs
      console.log(this.item.order)
      console.log(this.indicator)
      //console.log({ sorting: data });
      this.$emit('sort-user', data)
    },
    getOrder: function(currentOrder) {
      if (typeof currentOrder === 'undefined') {
        // not sorted yet, return default sorting order
        return 'desc'
      }
      // already sorted, return the opposite order
      return currentOrder === 'asc' ? 'desc' : 'asc'
    },
  },
}
</script>

<style scoped>
.px-custom {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
</style>
