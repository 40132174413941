<template>
  <div class="flex items-center font-semibold text-oDark text-28px">
    <slot>{{ title }}</slot>
    <div
      v-if="!hidePlus"
      class="flex items-center justify-center w-6 h-6 ml-2 rounded-full cursor-pointer oto-plus-bg"
      @click="handleClickingPlus()"
    >
      <svg
        class="w-6 h-6 text-white"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitlePlus',
  props: {
    title: {
      type: String,
    },
    hidePlus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickingPlus() {
      this.$emit('plus')
    },
  },
}
</script>

<style lang="scss" scoped>
.oto-plus-bg {
  background-color: rgb(41, 120, 255);
}
</style>
