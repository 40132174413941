<template>
  <div class="relative">
    <div
      class="absolute top-0 left-0 flex w-10 h-full border border-transparent"
    >
      <div
        class="z-10 flex items-center justify-center w-full h-full text-lg text-gray-600 bg-gray-100 rounded-l-full"
      >
        <svg
          class="w-6 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </div>
    </div>

    <input
      type="search"
      :placeholder="
        type == 'livemap' ? 'Search By IMEI or QR Code..' : 'Search..'
      "
      :value="value"
      @input="debouncedUpdate"
      @focus="focusUpdate"
      v-on:blur="blurUpdate"
      :class="
        `oto-search relative py-1.5 pl-12 pr-2 text-sm border border-gray-300 rounded-full sm:text-base focus:border-gray-800 focus:outline-none focus:ring-0 oto-search-input-size ${
          type == 'livemap' ? 'oto-search-input-size-full' : ''
        }`
      "
    />
  </div>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  name: 'OtoSearch',
  props: ['value', 'type'],
  methods: {
    debouncedUpdate: debounce(function(e) {
      this.$emit('input', e.target.value)
    }, 2000),
    focusUpdate: debounce(function(e) {
      if (this.type == 'livemap') this.$emit('input', e.target.value)
    }, 400),
    blurUpdate: function() {
      setTimeout(() => {
        if (this.type == 'livemap') this.$emit('dismiss')
      }, 400)
    },
  },
}
</script>

<style>
.oto-search-input-size {
  width: 198px;
  height: 35px;
}

.oto-search-input-size-full {
  width: 100% !important;
}

.oto-search::-webkit-search-cancel-button {
  /* -webkit-appearance: none; */

  /* position: relative; */
  /* right: 10px; */

  /* height: 15px;
  width: 15px;
  content: "x";
  color: #fff;
  border-radius: 10px;
  background: #323841;*/
}
</style>
