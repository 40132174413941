<template>
  <div @click="$emit('click')" role="menuitem" :class="`${getVariantClass}`">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'TDropdownItem',
  props: {
    variant: { required: false, default: 'primary' },
  },
  computed: {
    getVariantClass() {
      let base =
        'block bg-white px-4 py-2 text-sm leading-5 transition duration-150 ease-in-out cursor-pointer focus:outline-none '
      let variants = {
        primary: 'text-gray-700 hover:bg-gray-100 focus:bg-gray-100',
        danger: 'hover:text-red-500 hover:bg-red-100 focus:bg-red-100',
      }

      let variant = Object.keys(variants).includes(this.variant)
        ? this.variant
        : 'primary'

      return base + variants[variant]
    },
  },
}
</script>
