export const BlackListedRiderUserConfig = {
  api: {
    // get
    index: '/dashboard/org-users/blacklisted-user-list/',
    blockOrWhiteUser: '/dashboard/org-users/change-ipphone-fraud-status/',
  },
  events: {
    name: 'blacklisted-rider',
    refresh: 'refresh-blacklist-rider',
  },
}
