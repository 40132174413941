import dayjs from 'dayjs'
/*
 * Full featured fsTable, use the mixins in the following order
 * 1st: queryStringMixin -> 2nd: queryStringSearchMixin -> 3rd: queryStringFiltersMixin
 *
 */
const queryStringMixin = {
  data: function() {
    return {
      // default page is 1
      currentPage: 1,
      // limit means items per page
      currentLimit: 10,
      // query string
      queryString: {
        page: 1,
        limit: 10,
        offset: 0,
        search: '',
        // Filtering by date
        // start_date: this.getLast28Day(),
        // end_date: this.getToday(), //made by gunag
        // Sorting by field name
        sort: '',
        order: '',
        // Vehicle Status Filter
        on_ride: '',
        on_reservation: '',
        in_task: '',
        parking: '',
        // Flag
        damaged: '',
        missing: '',
        iot_fault: '',
        maintenance: '',
        // Battery Filter
        min_power_level: '',
        max_power_level: '',
        // Connectivity Filter
        active: '',
        // Lock Status Filter
        locked: '',
        fleets: [],
      },
      queryStringOptions: {
        prepend: '?',
        append: '',
      },
    }
  },
  mounted() {
    this.handleCurrentPageUpdates()
    this.handleCurrentLimitUpdates()
  },
  computed: {
    offset: function() {
      return (this.currentPage - 1) * this.currentLimit
    },
    paginationSummary: function() {
      const total = this.indexMetaData.count.total
      let index = this.offset + 1
      let offset = this.offset + this.currentLimit
      if (index > total) {
        index = total
      }
      if (offset > total) {
        offset = total
      }
      return `Showing ${index} - ${offset} of ${total} items`
    },
    getQueryString: function() {
      let queryString = this.queryStringOptions.prepend
      let keys = Object.keys(this.queryString)
      let values = Object.values(this.queryString)
      for (let i = 0; i < keys.length; i++) {
        if (values[i] === '') {
          continue
        }
        if (Array.isArray(values[i])) {
          // used for arrays
          for (let key in values[i]) {
            queryString += keys[i] + '=' + values[i][key] + '&'
          }
        } else {
          queryString += keys[i] + '=' + values[i] + '&'
        }
      }
      return queryString.slice(0, -1) + this.queryStringOptions.append
    },
  },
  watch: {
    currentPage: function(newCurrentPage) {
      // update the pagination query string
      this.queryString.page = newCurrentPage
      this.queryString.offset = (newCurrentPage - 1) * this.currentLimit
    },
    currentLimit: function(newCurrentLimit) {
      this.queryString.limit = newCurrentLimit
    },
  },
  methods: {
    handleCurrentLimitUpdates() {
      window.addEventListener('current-limit-updated', (e) => {
        this.currentLimit = e.detail.updatedLimit
      })
    },
    handleCurrentPageUpdates() {
      window.addEventListener('current-page-updated', (e) => {
        this.currentPage = e.detail.updatedPage
      })
    },
    getToday() {
      const today = dayjs()
      return today.format('YYYY-MM-DD')
    },
    getLast28Day() {
      const last28day = dayjs().subtract(27, 'day')
      return last28day.format('YYYY-MM-DD')
    },
  },
}

export default queryStringMixin
