const getIndexDataMixin = {
  data: function() {
    return {
      indexData: [],
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: '',
      viewPermissionStatus: null,
    }
  },
  watch: {
    // watch queryString & re-fetch
    getQueryString: async function(updatedQueryString) {
      await this.getIndexData(updatedQueryString)
    },
  },
  async created() {
    // console.log(this.getQueryString);
    this.getIndexData(this.getQueryString)
  },
  methods: {
    getIndexData: async function(queryString) {
      this.isLoading = true
      // todo: remove log
      // console.log(taxApi.index + "indexData");
      try {
        const queryUrl = this.indexDataEndpoint + queryString
        const response = await this.$http.get(queryUrl)
        this.$store.dispatch(
          'navigation/updateCurrentListAPIQuery',
          `${queryUrl}`
        )
        this.indexData = response.data.data
        this.indexMetaData = response.data.meta
        // todo: remove log
      } catch (error) {
        // this.errors.push(error);
        // todo: remove log
        this.viewPermissionStatus =
          error.response.data.detail === 'NOT ALLOWED TO THIS PAGE!'
            ? true
            : false
      }
      this.isLoading = false
    },
  },
}

module.exports = getIndexDataMixin
