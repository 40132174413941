<template>
  <section class="w-full bg-white rounded-md" :class="`${getSpacing}`">
    <slot></slot>
  </section>
</template>
<script>
export default {
  name: 'ContentSection',
  props: {
    spacing: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getSpacing() {
      return this.spacing ? 'px-6 py-3 mt-2' : ''
    },
  },
}
</script>
