<template>
  <div
    class="flex flex-col justify-center px-3 text-white card-tax-stat-container"
    :class="variantClass"
  >
    <div class="text-xs leading-normal uppercase" :style="{ color: textColor }">
      {{ title }}
    </div>
    <div class="flex items-center justify-between mt-2">
      <span class="text-xl font-semibold" :style="{ color: textColor }">{{
        value
      }}</span>
      <i :class="icon_class" style="font-size:22px;"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaxStat',
  props: {
    title: {
      required: true,
      type: String,
    },
    value: {
      required: true,
    },
    variant: {
      type: String,
      default: 'orange',
    },
    icon_class: {
      required: false,
    },
  },
  computed: {
    textColor: function() {
      return this.variant == 'yellow' ? '#030303' : 'white'
    },
    variantClass: function() {
      const validVariants = [
        'orange',
        'green',
        'red',
        'blue',
        'yellow',
        'light-blue',
        'purple',
        'white-purple',
      ]
      let cssClass = 'variant-orange'

      if (!validVariants.includes(this.variant)) {
        return cssClass
      }
      cssClass = `variant-${this.variant}`
      return cssClass
    },
  },
}
</script>

<style>
.card-tax-stat-container {
  width: 190px;
  height: 70px;
  border-radius: 5px;
}
.card-tax-stat-container.variant-orange {
  background-color: #f67606;
}
.card-tax-stat-container.variant-yellow {
  background-color: #ffed00;
}
.card-tax-stat-container.variant-blue {
  background-color: #0053ff;
}
.card-tax-stat-container.variant-light-blue {
  background-color: #4aaafd;
}
.card-tax-stat-container.variant-green {
  /* background-color: #23c977; */
  background-color: #09aa35;
}
.card-tax-stat-container.variant-red {
  background-color: #d90a20;
}
.card-tax-stat-container.variant-purple {
  background-color: #7d3cf8;
}
.card-tax-stat-container.variant-white-purple {
  background-color: #a600ff;
}
</style>
