<template>
  <div :class="`flex items-center justify-start `">
    <div
      :style="
        `position: relative; height: ${height}; width: calc(${width} + 4px)`
      "
    >
      <div :style="getBatteryFillable" />
      <div :style="getBatteryCap" />

      <div :style="getBatteryFill" />
    </div>

    <!-- <div class="w-1 h-2 bg-battery-head" /> -->

    <span :class="textClass">{{ getBatteryLevel }}</span>
  </div>
</template>

<script>
export default {
  name: 'BatteryStatus',

  props: {
    level: {
      type: [Number, String],
      required: false,
      default: 0.0,
    },

    height: {
      type: String,
      required: false,
      default: '30px',
    },

    width: {
      type: String,
      required: false,
      default: '55px',
    },

    vartical: {
      type: Boolean,
      required: false,
      default: false,
    },

    textClass: {
      type: String,
      required: false,
      default: 'ml-2 battery-value',
    },
  },

  computed: {
    getBatteryLevel() {
      let level = Number(this.level).toFixed()
      return `${level}%`
    },

    getBatteryFillable() {
      let style = `position: absolute; left:0; top:0; border-radius: 3px; background-color: #ededed;`

      style += `width : ${this.width};`
      style += `height : ${this.height};`

      return style
    },

    getBatteryCap() {
      let style = `position: absolute;`

      if (this.level >= 100) {
        style += `background-color: #0ac84b;` // green
      } else {
        style += `background-color: #ededed;` // gray
      }

      let width, height

      if (this.vartical) {
        width = '5px'
        height = `4px`
      } else {
        width = '4px'
        height = `8px`
      }

      style += `width: ${width};`
      style += `height: ${height};`

      if (this.vartical) {
        style += `left: calc((${this.width} - ${width}) / 2);`
        style += `bottom: ${this.height};`

        style += `border-top-left-radius: 3px;`
        style += `border-top-right-radius: 3px;`
      } else {
        style += `top : calc((${this.height} - ${height}) / 2);`
        style += `left : ${this.width};`

        style += `border-top-right-radius: 3px;`
        style += `border-bottom-right-radius: 3px;`
      }

      return style
    },

    getBatteryFill() {
      let style = `position: absolute; left:0; border-radius: 3px;`
      const level =
        this.level <= 0
          ? 0
          : this.level >= 100
          ? 100
          : parseFloat(this.level).toFixed()

      // console.log({ level, o: this.level })
      if (level <= 29) {
        style += 'background-color: #ff0000;'
      } else if (level >= 30 && level <= 69) {
        style += 'background-color: #ffaf00;'
      } else if (level >= 70) {
        style += 'background-color: #0ac84b;'
      }

      const dec = (level / 100).toFixed(2)

      if (this.vartical) {
        style += 'bottom: 0;'
        style += `height: calc(${this.height} * ${dec});`
        style += `width: ${this.width};`
      } else {
        style += 'top: 0;'
        style += `width: calc(${this.width} * ${dec});`
        style += `height: ${this.height};`
      }

      return style
    },
  },
}
</script>

<style lang="scss" scoped>
.battery-cap {
  border-radius: 3px;
  // background-color: #e6e5e5;
  background-color: red;
}

.battery-value {
  font-size: 16px;
  color: #2e2e39;
}
</style>
