const queryStringSearchMixin = {
  data: function() {
    return {
      // use an input with v-model bind to search
      search: '',
    }
  },
  watch: {
    // todo: debounce the search
    // This is meant to be used with fs-table-* components
    // in conjunction with queryStringMixin
    search: function(newSearchQuery) {
      // set the pagination to empty-string so it'll be skipped by getQueryString()
      this.queryString.page = ''
      this.queryString.offset = ''
      this.currentPage = 1

      // update the search queryString
      this.queryString.search = newSearchQuery
    },
  },
}

module.exports = queryStringSearchMixin
