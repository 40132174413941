<template>
  <td
    :class="
      `px-3 py-5 font-medium text-14px table-row-text ${
        className
          ? className
          : type == 'permission' && 'permission-border text-center'
      }`
    "
  >
    <slot></slot>
  </td>
</template>

<script>
export default {
  props: {
    type: {
      required: false,
    },
    className: {
      required: false,
      type: String,
      default: null,
    },
  },
}
</script>
<style scoped>
.permission-border {
  border-right: 1px solid #edf2f7;
}
.table-row-text {
  color: #2e2e39;
}
</style>
