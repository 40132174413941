export const LockConfig = {
  api: {
    // get
    index: '/dashboard/locks/',
    // post
    create: '/dashboard/locks/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/locks/${id}/`
    },
  },
  events: {
    name: 'lock',
    // refresh-index-data
    refresh: `rid-lock`,
    // slideover-right
    sorId: 'lock',
    sorOpen: 'sor-open-lock',
    sorClose: 'sor-close-lock',
    sorToggle: 'sor-toggle-lock',
    // editing-data
    editingData: 'edit-lock-data',
    // viewing-data
    viewingData: 'view-lock-data',
  },
}
