<template>
  <div class="x-status" :class="`${getVariantClass} ${getProfileClass}`">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'XStatus',
  props: {
    text: {
      required: true,
      type: String,
    },
    variant: {
      type: String,
      default: 'green',
    },
    profile: {
      type: String,
      default: 'trip',
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    getVariantClass: function() {
      const validVariants = [
        'orange',
        'green',
        'red',
        'blue',
        'yellow',
        'light-blue',
        'purple',
        'gray',
        'blush',

        // alt/ultra (lighter alternative)
        'alt-gray',
        'alt-blue',
        'alt-green',
        'ultra-green',
        'alt-orange',
        'alt-red',
      ]
      if (validVariants.includes(this.variant)) {
        return `variant-${this.variant}`
      }
      return 'variant-orange'
    },
    getProfileClass() {
      return `profile-${this.profile}`
    },
  },
}
</script>

<style lang="scss" scoped>
.x-status {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  height: 19px;
  border-radius: 3px;

  &.profile-base {
    width: auto;
    height: auto;
  }

  &.profile-report-category-chip {
    width: auto;
    height: auto;
    padding: 2px 4px;
  }

  &.profile-trip {
    width: 89px;
  }
  &.profile-fleet {
    width: 63px;
  }
  &.profile-priority {
    width: 70px;
  }
  &.profile-pass {
    width: 75px;
  }
  &.profile-payment {
    width: 63px;
  }
  &.profile-task {
    width: 110px;
  }
  &.profile-speed {
    width: 89px;
  }
  &.profile-user {
    width: 89px;
  }
  &.profile-dispute {
    width: 120px;
  }

  &.variant-alt-gray {
    color: #7e8494;
    background-color: #e3e8ee;
  }
  &.variant-alt-blue {
    color: #5f72bf;
    background-color: #d7ebff;
  }
  &.variant-alt-green {
    color: #09aa35;
    background-color: #d1fae5;
  }
  &.variant-ultra-green {
    color: #ffffff;
    background-color: #22c55e;
  }
  &.variant-alt-red {
    color: #d73434;
    background-color: #ffd1d1;
  }
  &.variant-alt-orange {
    color: #ffa500;
    background-color: #fffbcc;
  }

  &.variant-orange {
    background-color: #f67606;
  }
  &.variant-yellow {
    background-color: #ffed00;
    color: #030303;
  }
  &.variant-blue {
    background-color: #0053ff;
  }
  &.variant-light-blue {
    background-color: #4aaafd;
  }
  &.variant-green {
    background-color: #09aa35;
  }
  &.variant-red {
    background-color: #d90a20;
  }
  &.variant-purple {
    background-color: #7d3cf8;
  }
  &.variant-gray {
    background-color: #b4b9bc;
  }
  &.variant-blush {
    background-color: #dc143c;
  }
}
</style>
