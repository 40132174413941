<template>
  <div>
    <transition name="fade">
      <button
        v-if="visibility"
        @click="close(false)"
        tabindex="-1"
        class="fixed inset-0 w-full h-full opacity-50 cursor-default bg-oBlack"
        style="z-index: 1050;"
      />
    </transition>

    <transition
      name="SOR"
      enter-active-class="transition duration-300 ease-out transform"
      leave-active-class="transition duration-300 ease-in transform"
      enter-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div
        v-if="visibility"
        class="fixed bottom-0 right-0 h-full px-4 bg-white border-l-2 border-gray-400 border-opacity-50 shadow-lg container-sor"
        :class="getEdgeStackSize"
      >
        <section v-if="busy" class="py-4">
          <TheSuspense v-for="n in 3" :key="`es-skeletons-${n}`" rounded>
            <SuspenseHeading img />
            <SuspenseText :lines="5" />
          </TheSuspense>
        </section>

        <template v-else>
          <header :class="computeHeaderClassName">
            <slot name="header"></slot>
          </header>

          <div class="pb-4 overflow-x-hidden overflow-y-auto sb-farhan">
            <slot></slot>
          </div>

          <!-- https://stackoverflow.com/questions/52377022/is-there-a-way-to-detect-if-a-scoped-slot-was-passed-in-to-a-component -->
          <div v-if="hasFooterSlot" class="w-full">
            <footer
              :class="
                `${footerClass.fixed} ${footerClass.append} ${
                  exitButton.hidden ? '' : 'justify-between'
                } `
              "
            >
              <div v-if="exitButton.hidden === false">
                <anchor-button
                  :variant="getCloseButtonVariant"
                  :class="``"
                  :isLoading="false"
                  @click="close(!confirmFirst)"
                >
                  Cancel
                </anchor-button>
              </div>
              <div class="flex items-center text-sm">
                <slot
                  name="footer"
                  v-bind="{ close, confirmFirst, exitButton }"
                >
                  Footer Slot
                </slot>
              </div>
            </footer>
          </div>
        </template>
      </div>
    </transition>

    <!-- confirmFirst -->
    <div
      v-if="confirmFirstVisibility"
      class="fixed inset-0 w-full h-full transition-all duration-100 ease-in-out bg-gray-900 bg-opacity-50"
      style="z-index: 1105"
    >
      <section
        class="relative max-w-lg mx-auto my-0 mt-20 overflow-y-auto transition-all duration-300 ease-in-out"
        style="z-index: 1110; "
      >
        <div
          class="relative overflow-hidden bg-white border border-gray-600 rounded-md shadow-lg"
        >
          <section
            class="flex items-center justify-center mt-8 mb-2 text-gray-500"
          >
            <i class="text-4xl fas fa-exclamation-circle" />
          </section>

          <section class="px-4 text-2xl font-extrabold text-center">
            {{ confirmQuestion }}
          </section>

          <section class="px-4 font-bold text-center">
            All changes will be discarded
          </section>

          <section
            class="flex items-center px-4 py-4 mt-6 space-x-5 bg-gray-100"
          >
            <anchor-button
              :variant="'warning-alt'"
              :class="`w-1/2`"
              :isLoading="false"
              @click="close(true)"
            >
              Exit
            </anchor-button>

            <anchor-button
              :variant="'primary'"
              :class="`w-1/2`"
              :isLoading="false"
              @click="closeConfirmFirst"
            >
              Cancel
            </anchor-button>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { EdgeStackManager, edgeStackEmitter } from '@/plugins/edgeStack'

export default defineComponent({
  components: {
    AnchorButton: () => import('@/components/form/AnchorButton.vue'),
  },
  name: 'EdgeStack',
  props: {
    // unique id
    id: {
      type: String,
      required: true,
    },
    // weather to confirm before closing
    confirm: {
      type: Boolean,
      required: false,
      default: false,
    },
    confirmQuestion: {
      type: String,
      required: false,
      default: 'Are you sure you want to exit?',
    },
    // size
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    sizeClass: {
      type: String,
      required: false,
      default: '',
    },
    headerClassName: {
      type: String,
      required: false,
      default: 'mt-16 px-4 mb-6',
    },
    // state
    value: {
      type: Boolean,
      required: false,
      default: false,
    },

    busy: {
      type: Boolean,
      default: false,
    },

    //
    exitButton: {
      type: Object,
      default: () => ({
        text: 'Cancel',
        hidden: false,
      }),
    },

    footerClass: {
      type: Object,
      default: () => ({
        fixed: 'flex items-center px-6 pt-2 pb-5 w-full h-auto',
        append: '',
      }),
    },

    // toggle debug mode
    debug: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { slots, scopedSlots }) {
    props.debug && console.log('edgeStack', slots, scopedSlots)
    const hasFooterSlot = computed(() => {
      const hasFooter = slots?.footer ?? scopedSlots?.footer() ?? false
      props.debug &&
        console.log(
          'hasFooterSlot, slots, scopedSlots:',
          !!hasFooter,
          slots,
          scopedSlots
        )
      return !!hasFooter
    })

    // Edge stack manager
    const edgeStackManager = new EdgeStackManager()

    const visibility = ref(props.value)

    watch(visibility, (updatedState) => {
      if (updatedState === true) {
        // prevent scrolling
        document.body.style.overflow = 'hidden'
        // Adding margin-right due to scrollbar flickering issue
        document.body.style.marginRight = '11px'
      } else {
        // wait for the animation to finish
        setTimeout(function() {
          document.body.style.overflow = 'auto'
          document.body.style.marginRight = ''
        }, 200)
      }
    })

    // Should confirm first before quiting? Can be managed via events
    const confirmFirst = ref(false)
    // State of visibility of confrim first dialog. Managed internally based on confirmFirst
    const confirmFirstVisibility = ref(false)

    // Computed
    const getCloseButtonVariant = computed(() => {
      return confirmFirst.value === true ? 'warning-alt' : 'secondary'
    })

    const getEdgeStackSize = computed(() => {
      if (props.sizeClass) return props.sizeClass

      return props.fullWidth ? 'w-full' : 'w-full md:w-3/6 2xl:w-2/5'
    })

    // Methods
    const open = () => {
      // emit opening event
      edgeStackEmitter.emit(edgeStackManager.getEventName('opening', props.id))

      // breathing time from opening event
      setTimeout(() => {
        visibility.value = true
      }, 250)

      // wait for the animation to finish
      setTimeout(() => {
        edgeStackEmitter.emit(edgeStackManager.getEventName('opened', props.id))
      }, 350)
    }

    const close = (confirmed = false) => {
      props.debug &&
        console.log({
          onClose_ConfirmFirst: confirmFirst.value,
          onClose_Confirmed: confirmed,
        })

      if (confirmFirst.value === true && confirmed === false) {
        confirmFirstVisibility.value = true
        return
      }

      confirmFirstVisibility.value = false
      confirmFirst.value = false

      // emit closing event
      edgeStackEmitter.emit(edgeStackManager.getEventName('closing', props.id))

      // breathing time from closing event
      setTimeout(() => {
        visibility.value = false
      }, 250)

      // wait for the animation to finish
      setTimeout(() => {
        edgeStackEmitter.emit(edgeStackManager.getEventName('closed', props.id))
      }, 350)
    }

    const toggle = () => {
      const isVisible = visibility.value
      if (isVisible === true) {
        close()
      } else {
        open()
      }

      edgeStackEmitter.emit(
        edgeStackManager.getEventName('toggled', {
          from: isVisible,
          to: visibility.value,
        })
      )
    }

    const closeConfirmFirst = () => {
      confirmFirstVisibility.value = false
    }

    const registerEvents = () => {
      edgeStackEmitter.on(
        edgeStackManager.getEventName('open', props.id),
        () => {
          open()
        }
      )

      edgeStackEmitter.on(
        edgeStackManager.getEventName('close', props.id),
        () => {
          close()
        }
      )

      edgeStackEmitter.on(
        edgeStackManager.getEventName('toggle', props.id),
        () => {
          toggle()
        }
      )

      // e.confirmFirst has to be a boolean
      edgeStackEmitter.on(
        edgeStackManager.getEventName('confirmFirst', props.id),
        (e) => {
          confirmFirst.value = e.confirmFirst
        }
      )

      props.debug &&
        console.log({
          EdgeStackComponents: { id: props.id, events: edgeStackEmitter.all },
        })
    }

    // todo: confirm first
    const unregisterEvents = () => {
      edgeStackEmitter.off(
        edgeStackManager.getEventName('open', props.id),
        () => {
          open()
        }
      )

      edgeStackEmitter.off(
        edgeStackManager.getEventName('close', props.id),
        () => {
          close()
        }
      )

      edgeStackEmitter.off(
        edgeStackManager.getEventName('toggle', props.id),
        () => {
          toggle()
        }
      )

      edgeStackEmitter.off(
        edgeStackManager.getEventName('confirmFirst', props.id)
      )

      props.debug &&
        console.log({
          EdgeStackComponents: { id: props.id, events: edgeStackEmitter.all },
        })
    }

    onMounted(registerEvents)

    onUnmounted(unregisterEvents)

    return {
      open,
      close,
      toggle,
      visibility,
      confirmFirst,
      confirmFirstVisibility,
      getEdgeStackSize,
      closeConfirmFirst,
      getCloseButtonVariant,
      computeHeaderClassName: computed(() => {
        return slots.header ? props.headerClassName : ''
      }),

      hasFooterSlot,
    }
  },
})
</script>

<style lang="scss" scoped>
.container-sor {
  display: grid;
  grid-template-rows: auto 1fr auto;
  z-index: 1100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
