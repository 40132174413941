export const MultipleScooterConfig = {
  api: {
    // get
    index: '/dashboard/organizations/',
    // get
    single: (id = 'uuid') => {
      return `/dashboard/organizations/${id}/`
    },
    // post [add-step-1]
    create: '/dashboard/organizations/',
    multiple: '/dashboard/vehicles/bulk-create/',
    fleets: '/dashboard/fleets/',
    // patch [edit|add-step-2]
    update: (id = 'uuid') => {
      return `/dashboard/organizations/${id}/`
    },
    summary: {},
    profile: {},
    // fleets: {
    //   // todo: merge?
    //   // get
    //   index: "/dashboard/fleets/",
    //   create: ""
    // }
  },
  events: {
    name: 'multiple-scooter',
    // refresh
    refresh: `refresh-scooter`,
    // slideover-right
    sorId: 'scooter-multiple',
    sorOpen: 'sor-open-scooter-multiple',
    sorClose: 'sor-close-scooter-multiple',
    sorToggle: 'sor-toggle-scooter-multiple',
    // editing-data
    editingData: 'edit-scooter-multiple-data',
    successedMultiple: 'succeeded-multiple',

    // viewing-data
    //viewingData: "view-organization-data"
  },
}
