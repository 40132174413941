<template>
  <fs-table-body>
    <tr :class="`transition duration-100 border-b border-gray-200 ${showMode}`">
      <slot></slot>
    </tr>
  </fs-table-body>
</template>
<script>
import fsTableBody from '@/components/fsTable/fsTableBody'
export default {
  name: 'fsTableRow',
  props: {
    showMode: {
      required: false,
      default: String,
    },
  },
  components: {
    fsTableBody,
  },
}
</script>
<style scoped>
tbody:nth-child(odd) {
  background-color: #f7fafc;
}

/* todo: add hover effects */
/* hover:shadow-xl */
</style>
