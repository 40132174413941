import { render, staticRenderFns } from "./BatteryStatus.vue?vue&type=template&id=71c4495c&scoped=true&"
import script from "./BatteryStatus.vue?vue&type=script&lang=js&"
export * from "./BatteryStatus.vue?vue&type=script&lang=js&"
import style0 from "./BatteryStatus.vue?vue&type=style&index=0&id=71c4495c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c4495c",
  null
  
)

export default component.exports