// FsTable
import fsTableContainer from '@/components/fsTable/fsTableContainer'
import fsTable from '@/components/fsTable/fsTable'
import fsTableHead from '@/components/fsTable/fsTableHead'
import fsTableHeadItem from '@/components/fsTable/fsTableHeadItem'
import fsTableRow from '@/components/fsTable/fsTableRow'
import fsTableRowItem from '@/components/fsTable/fsTableRowItem'
import fsTableFoot from '@/components/fsTable/fsTableFoot'

export default {
  components: {
    // FsTable
    fsTableContainer,
    fsTable,
    fsTableHead,
    fsTableHeadItem,
    fsTableRow,
    fsTableRowItem,
    fsTableFoot,
  },
}
