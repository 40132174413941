<template>
  <t-dropdown variant="calendar">
    <div
      slot="trigger"
      slot-scope="{
        mousedownHandler,
        focusHandler,
        blurHandler,
        keydownHandler,
      }"
    >
      <button
        class="flex items-center justify-between h-12 px-6 text-sm text-gray-700 transition duration-150 ease-in-out bg-white border rounded-full shadow-md dropdown-button-size focus:outline-none focus:shadow-solid"
        aria-label="Calendar"
        aria-haspopup="true"
        @mousedown="mousedownHandler"
        @focus="focusHandler"
        @blur="blurHandler"
        @keydown="keydownHandler"
      >
        <div class="text-center">
          <div v-if="selectedRange.start && selectedRange.end" class>
            <div class="font-bold text-15px text-mDark">{{ printDate }}</div>
            <div class="font-semibold text-10px text-oLighterGray">
              {{ dateDiff }} report
            </div>
          </div>
          <div v-else>
            <div class="text-xs font-semibold text-oGray">Custom report</div>
          </div>
        </div>

        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </button>
    </div>

    <div
      class="flex justify-between p-1 rounded-md shadow-xs"
      slot-scope="{ hide }"
    >
      <div v-if="timeFilterEnabled" class="w-9/12">
        <v-range-selector
          :start-date.sync="selectedRange.start"
          :end-date.sync="selectedRange.end"
          class="w-full"
        />
        <div class="flex items-center justify-end h-20 bg-blue-50 pr-1">
          <section>
            <span class="font-bold text-gray-800 pr-1">Select Time Range:</span>
            <vue-timepicker
              close-on-complete
              format="hh:mm:ss A"
              placeholder="Select Start Time"
              v-model="selectedTimeRange.start"
            ></vue-timepicker>
            <span class="m-1">to</span>
            <vue-timepicker
              close-on-complete
              format="hh:mm:ss A"
              placeholder="Select End Time"
              v-model="selectedTimeRange.end"
            ></vue-timepicker>
          </section>
        </div>
      </div>
      <v-range-selector
        v-else
        :start-date.sync="selectedRange.start"
        :end-date.sync="selectedRange.end"
        class="w-9/12"
      />
      <!--
        Last 7 Days
        Last 28 Days(Default)
        Last 90 Days
        Last 12 month
        Lifetime
      -->
      <div class="flex flex-col justify-between w-3/12 ml-1 bg-gray-100">
        <div>
          <button
            :class="selectedButtonName === 'today' && `selected-button`"
            @click="setEasyDatePickRange('today')"
            class="block px-2 py-2 mt-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600 "
          >
            Today
          </button>
          <button
            :class="selectedButtonName === 'yesterday' && `selected-button`"
            @click="setEasyDatePickRange('yesterday')"
            class="block px-2 py-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Yesterday
          </button>
          <button
            :class="selectedButtonName === 'this-week' && `selected-button`"
            @click="setEasyDatePickRange('this-week')"
            class="block px-2 py-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            This Week
          </button>
          <button
            :class="selectedButtonName === 'last-week' && `selected-button`"
            @click="setEasyDatePickRange('last-week')"
            class="block px-2 py-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Last Week
          </button>
          <!-- <button
            @click="setEasyDatePickRange('this-month')"
            class="block px-2 py-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            This Month
          </button>-->
          <button
            :class="selectedButtonName === 'last-7days' && `selected-button`"
            @click="setEasyDatePickRange('last-7days')"
            class="block px-2 py-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Last 7 Days
          </button>
          <button
            :class="selectedButtonName === 'last-28days' && `selected-button`"
            @click="setEasyDatePickRange('last-28days')"
            class="block px-2 py-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Last 28 Days
          </button>
          <button
            :class="selectedButtonName === 'last-90days' && `selected-button`"
            @click="setEasyDatePickRange('last-90days')"
            class="block px-2 py-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Last 90 Days
          </button>
          <button
            :class="selectedButtonName === 'lifetime' && `selected-button`"
            @click="setEasyDatePickRange('lifetime')"
            class="block px-2 py-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600 "
          >
            Lifetime
          </button>
          <button
            :class="selectedButtonName === 'custom' && `selected-button`"
            @click="resetDateRange()"
            class="block px-2 py-2 text-sm rounded-sm cursor-pointer text-calendar hover:text-gray-600"
          >
            Custom
          </button>
        </div>
        <div class="flex items-center justify-end my-3">
          <button
            @click="
              hide()
              handleClickingCancel()
            "
            class="flex px-4 font-semibold  leading-normal uppercase transition duration-150 ease-in-out bg-gray-300 rounded cursor-pointer place-items-center h-36px text-14px focus:outline-none  hover:text-gray-900 focus:bg-gray-300"
          >
            Cancel
          </button>
          <button
            :disabled="!isApply"
            @click="
              hide()
              handleClickingApplyDate()
            "
            :class="
              !isApply
                ? `cursor-not-allowed bg-oGray hover:border-gray-600`
                : `bg-oGreen hover:border-green-600`
            "
            class="flex px-4 ml-3 font-semibold leading-normal  text-gray-100 uppercase transition duration-150 ease-in-out border border-transparent rounded cursor-pointer place-items-center h-36px text-14px focus:outline-none   hover:text-white hover:opacity-75 my-btn"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </t-dropdown>
</template>

<script>
import VRangeSelector from './components/vl-range-selector'
import dayjs from 'dayjs'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
var isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

var isYesterday = require('dayjs/plugin/isYesterday')
dayjs.extend(isYesterday)

var weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)

export default {
  name: 'DateRangePicker',
  components: {
    VRangeSelector,
    VueTimepicker,
  },
  props: {
    timeFilterEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dateDiff: function() {
      if (
        this.selectedRange.start !== null &&
        this.selectedRange.end !== null
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isApply = true
        const start = dayjs(this.selectedRange.start)
        const end = dayjs(this.selectedRange.end)
        // Do addition of 1 to count the startign-day as well
        const diff = end.diff(this.selectedRange.start, 'day') + 1
        if (start.isToday() && end.isToday()) {
          return "Today's"
        }
        if (start.isYesterday() && end.isYesterday()) {
          return "Yesterday's"
        }
        if (this.selectedRange.start === '2020-01-01' && end.isToday()) {
          return 'Lifetime'
        }
        return 'Last ' + diff + ' days'
      }
      return 0
    },
    startDate: function() {
      const start = this.selectedRange.start
      if (start === this.birthday) {
        return dayjs(this.selectedRange.start).format(this.friendlyFormat)
      }
      return dayjs(start).format(this.friendlyShortFormat)
    },
    endDate: function() {
      return dayjs(this.selectedRange.end).format(this.friendlyFormat)
    },
    printDate() {
      // show only one date when range has same date
      if (this.selectedRange.start === this.selectedRange.end) {
        return dayjs(this.selectedRange.start).format(this.friendlyFormat)
      }
      // show 1 Jan, 2020 - Today -> Lifetime report
      const end = dayjs(this.selectedRange.end)
      if (this.selectedRange.start === '2020-01-01' && end.isToday()) {
        return (
          dayjs(this.selectedRange.start).format(this.friendlyFormat) +
          ' - Today'
        )
      }
      return this.startDate + ' - ' + this.endDate
    },
  },

  data() {
    return {
      selectedRange: {
        // start: this.getLast7Day(), //made by guang  //this.getLast28Day(),
        start: this.getBirthDay(), // reverted changes of guang : farhan,
        end: this.getToday(),
      },
      previousSelectedRange: {
        start: this.getBirthDay(),
        end: this.getToday(),
      },
      selectedTimeRange: {
        start: '',
        end: '',
      },
      format: 'YYYY-MM-DD',
      friendlyFormat: 'DD MMM YYYY',
      friendlyShortFormat: 'DD MMM',
      birthday: '2020-01-01',
      isApply: true,
      // restorePreviousData: false,
      selectedButtonName: 'lifetime',
      previousSelectedButton: 'lifetime',
    }
  },
  methods: {
    handleClickingApplyDate() {
      if (this.timeFilterEnabled) {
        if (
          this.isTime(this.selectedTimeRange.start) &&
          this.isTime(this.selectedTimeRange.end)
        ) {
          const startUTCDateTime = this.getUTCDateTime(
            this.selectedRange.start,
            this.selectedTimeRange.start
          )

          const endUTCDateTime = this.getUTCDateTime(
            this.selectedRange.end,
            this.selectedTimeRange.end
          )
          const startDate = this.getUTCDate(startUTCDateTime)
          const endDate = this.getUTCDate(endUTCDateTime)
          const startTime = this.getUTCTime(startUTCDateTime)
          const endTime = this.getUTCTime(endUTCDateTime)

          this.$emit('apply-date', {
            start: startDate || null,
            end: endDate || null,
            startTime: startTime || null,
            endTime: endTime || null,
          })
        } else {
          this.$emit('apply-date', {
            start:
              this.getUTCDate(
                this.getUTCDateTime(this.selectedRange.start, '00:00:00')
              ) || null,
            end:
              this.getUTCDate(
                this.getUTCDateTime(this.selectedRange.end, '23:59:59')
              ) || null,
            startTime:
              this.getUTCTime(
                this.getUTCDateTime(this.selectedRange.start, '00:00:00')
              ) || null,
            endTime:
              this.getUTCTime(
                this.getUTCDateTime(this.selectedRange.end, '23:59:59')
              ) || null,
          })
        }
      } else {
        this.$emit('apply-date', {
          start: this.selectedRange.start || null,
          end: this.selectedRange.end || null,
        })
      }
      // this.restorePreviousData = true
      this.previousSelectedRange = this.selectedRange
      this.previousSelectedButton = this.selectedButtonName
    },
    handleClickingCancel() {
      this.selectedRange = this.previousSelectedRange
      this.selectedButtonName = this.previousSelectedButton
      // this.$emit('cancel-date', {
      //   start: this.previousSelectedRange.start || null,
      //   end: this.previousSelectedRange.end || null,
      // })
    },
    isTime(rawTime) {
      if (!rawTime) return false
      const [time, modifier] = rawTime.split(' ')
      let [hours, minutes, seconds] = time.split(':')
      if (hours.includes('h')) return false
      if (minutes.includes('m')) return false
      if (seconds.includes('s')) return false
      if (modifier.length < 2) return false
      return true
    },
    convertTimeTo24Hour(timeStr) {
      if (!timeStr) return ''
      const [time, modifier] = timeStr.split(' ')
      let [hours, minutes, seconds] = time.split(':')
      if (hours === '12') {
        hours = '00'
      }
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12
      }
      return `${hours}:${minutes}:${seconds}`
    },
    //M->Month, D->Day, Y->Year
    convertDateToMDY(dateStr) {
      if (!dateStr) return ''
      const [year, month, day] = dateStr.split('-')
      return `${month}/${day}/${year}`
    },
    getStandardNumber(number) {
      if (number < 10) return `0${number}`
      else return number
    },
    getUTCDateTime(date, time) {
      const dateTime = new Date(
        `${this.convertDateToMDY(date)} ${this.convertTimeTo24Hour(time)}`
      )
      return dateTime
    },
    getUTCDate(str) {
      return `${str.getUTCFullYear()}-${this.getStandardNumber(
        str.getUTCMonth() + 1
      )}-${this.getStandardNumber(str.getUTCDate())}`
    },
    getUTCTime(str) {
      return `${this.getStandardNumber(
        str.getUTCHours()
      )}:${this.getStandardNumber(
        str.getUTCMinutes()
      )}:${this.getStandardNumber(str.getUTCSeconds())}`
    },
    resetDateRange() {
      this.isApply = false
      // this.previousSelectedRange = this.selectedRange

      this.selectedRange = {}
      // this.restorePreviousData = true
      this.selectedButtonName = 'custom'
    },
    easyDatePick(option) {
      let range = {}

      const format = this.format
      const today = dayjs()

      if (option === 'today') {
        range.start = today.format(format)
        console.log('today-click', dayjs().format(this.format))
        range.end = today.format(format)
        this.selectedButtonName = 'today'
        // console.log(range);
        return range
      }

      if (option === 'yesterday') {
        const yesterday = today.subtract(1, 'day')

        range.start = yesterday.format(format)
        range.end = yesterday.format(format)
        this.selectedButtonName = 'yesterday'
        // console.log(range);
        return range
      }

      if (option === 'this-week') {
        // this week's last monday-today
        range.start = today.weekday(0).format(format)
        range.end = today.format(format)
        this.selectedButtonName = 'this-week'
        // console.log(range);
        return range
      }

      if (option === 'last-week') {
        // last week's monday-sunday
        range.start = today.weekday(-6).format(format)
        range.end = today.weekday(0).format(format)
        this.selectedButtonName = 'last-week'
        return range
      }

      if (option === 'last-7days') {
        range.start = today.subtract(6, 'day').format(format)
        range.end = today.format(format)
        this.selectedButtonName = 'last-7days'
        return range
      }

      if (option === 'last-28days') {
        range.start = today.subtract(27, 'day').format(format)
        range.end = today.format(format)
        this.selectedButtonName = 'last-28days'
        return range
      }

      if (option === 'last-90days') {
        range.start = today.subtract(89, 'day').format(format)
        range.end = today.format(format)
        this.selectedButtonName = 'last-90days'
        return range
      }

      if (option === 'this-month') {
        range.start = today.startOf('month').format(format)
        range.end = today.endOf('month').format(format)
        this.selectedButtonName = 'this-month'
        // console.log(range);
        return range
      }

      if (option === 'this-year') {
        range.start = today.startOf('year').format(format)
        range.end = today.endOf('year').format(format)
        this.selectedButtonName = 'this-year'
        // console.log(range);
        return range
      }

      if (option === 'lifetime') {
        // from the birth of the company to today
        range.start = dayjs('2020-01-01').format(format)
        console.log('lifetime-start', range.start)
        range.end = today.format(format)
        this.selectedButtonName = 'lifetime'
        return range
      }

      return range
    },
    setEasyDatePickRange(option) {
      const validOptions = [
        'today',
        'yesterday',
        'this-week',
        'last-week',
        'last-7days',
        'this-month', // not needed
        'last-28days',
        'last-90days',
        'last-12months',
        'this-year',
        'lifetime', // no date range
        'custom', // custom date range
      ]
      if (!validOptions.includes(option)) {
        this.selectedRange = this.easyDatePick('today')
        return
      }
      this.selectedRange = this.easyDatePick(option)
    },
    getToday() {
      const today = dayjs()
        .format(this.format)
        .split('T')[0]

      return today
    },
    getLast7Day() {
      const last7day = dayjs().subtract(6, 'day')
      return last7day.format(this.format)
    },
    getLast28Day() {
      // const today = dayjs();
      const last28day = dayjs().subtract(27, 'day')
      return last28day.format(this.format)
    },
    getBirthDay() {
      const birthday = '2020-01-01'
      return birthday
    },
  },
}
</script>

<style lang="scss">
$vl-primary: #3e3a4e;
$vl-gray-2: #f7fafc;
@import './scss/vuelendar.scss';
.text-calendar {
  color: $vl-primary;
  &:hover {
    color: $vl-gray-2;
    background-color: $vl-primary;
    border-radius: 5px;
  }
}
.dropdown-button-size {
  width: 260px;
  height: 50px;
}
.text-15px {
  font-size: 15px;
}
.text-10px {
  font-size: 10px;
}
.text-mDark {
  color: #323740;
}
.selected-button {
  background-color: var(--brand-primary-color);
  color: white;
  @apply rounded;
}

.selected-button:hover {
  opacity: 0.75;
}

.my-btn {
  background-color: var(--brand-primary-color);
  color: white;
}
.my-btn:hover {
  opacity: 0.75;
}
</style>
