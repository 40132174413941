<template>
  <div>
    <div
      @click="selectAvatar"
      class="relative w-24 h-24 p-px bg-gray-300 border-0 rounded-full cursor-pointer"
    >
      <div
        class="absolute z-10 flex items-center justify-center w-5 h-5 bg-gray-800 rounded-full place-items-center"
        style="top: 63.5%; right: -3%"
      >
        <svg
          class="w-4 h-4 text-white"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          ></path>
        </svg>
      </div>
      <img
        v-show="current && !previewAvatar"
        :src="current"
        class="object-cover w-24 h-24 overflow-hidden rounded-full"
      />
      <img
        v-show="previewAvatar"
        :src="previewAvatar"
        class="object-cover w-24 h-24 overflow-hidden rounded-full"
      />
    </div>
    <input
      ref="fileInput"
      class="hidden"
      type="file"
      @input="handleAvatarSelection"
    />
  </div>
</template>

<script>
export default {
  name: 'UploadAvatar',
  data: function() {
    return {
      previewAvatar: null,
    }
  },
  props: {
    value: {
      required: false,
    },
    current: {
      required: false,
    },
  },
  methods: {
    selectAvatar() {
      this.$refs.fileInput.click()
    },
    handleAvatarSelection() {
      let input = this.$refs.fileInput
      let file = input.files

      if (file && file[0]) {
        let reader = new FileReader()
        reader.onload = (e) => {
          this.previewAvatar = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
  },
}
</script>

<style></style>
