<template>
  <div
    class="flex flex-col items-center w-full px-2 md:flex-row md:justify-between"
  >
    <div
      class="flex items-center justify-center w-full mt-6 md:justify-start md:w-1/3 md:mt-0"
    >
      <span>Show</span>
      <t-select
        variant="tableEntry"
        v-model.number="limit"
        class="ml-2"
        :options="[
          { value: 10, text: '10' },
          { value: 15, text: '15' },
          { value: 25, text: '25' },
        ]"
      />
      <span class="ml-2">Entries</span>
    </div>

    <div class="hidden w-1/3 text-center md:block">
      {{ paginationSummary }}
    </div>
    <div class="w-full md:w-1/3 md:flex md:items-end md:justify-end">
      <t-pagination
        :total-items="totalItems"
        :per-page="currentLimit"
        v-model="page"
        class="mt-6 mb-4"
        @change="paginationFired($event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentLimit: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    paginationSummary: {
      type: String,
      default: 'Showing 0 - 0 of 0 items',
    },
  },
  data: () => {
    return {
      page: 1,
      limit: 10,
    }
  },
  methods: {
    paginationFired($event) {
      console.log($event)
    },
  },
  watch: {
    page: function(updatedPage) {
      dispatchEvent(
        new CustomEvent('current-page-updated', {
          detail: {
            updatedPage: updatedPage,
          },
        })
      )
    },
    limit: function(updatedLimit) {
      dispatchEvent(
        new CustomEvent('current-limit-updated', {
          detail: {
            updatedLimit: updatedLimit,
          },
        })
      )
    },
    currentPage() {
      this.page = this.currentPage
    },
  },
  mounted() {
    this.page = this.currentPage
  },
}
</script>
